import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { Subcontractors } from './Subcontractors';
import { AllGroups } from './AllGroups';
import { Terminals } from './Terminals';
import { Customers } from './Customers';
import { Checkpoints } from './Checkpoints';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { Field, FieldProps, Form, Formik } from 'formik';
import { useStylesForSearch } from '../components/SearchInput';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useState } from 'react';

export function Card() {
  const classes = useStylesForSearch();
  const { t } = useTranslation();
  const [currentCard, setCurrentCard] = useState('All');
  const [searchText, setSearchText] = useState('');
  const [showGrid, setShowGrid] = useState(false);
  const [warning, setWarning] = useState(false);
  const path = useLocation();

  React.useEffect(() => {
    if (path.pathname.includes('All')) {
      setCurrentCard('All');
    }
    if (path.pathname.includes('subcontractors')) {
      setCurrentCard('Subcontractors');
    }
    if (path.pathname.includes('terminals')) {
      setCurrentCard('Terminals');
    }
    if (path.pathname.includes('checkpoints')) {
      setCurrentCard('Checkpoints');
    }
    if (path.pathname.includes('customers')) {
      setCurrentCard('Customers');
    }
  }, [path.pathname]);

  return (
    <>
      <Grid item container style={{ marginTop: 5 }}>
        <Typography align="left" variant="subtitle1">
          {`Cards/ ${currentCard}`}
        </Typography>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: 5 }}>
        <Grid item xs={3}>
          <TextField
            value={currentCard}
            onChange={(event) => setCurrentCard(event.target.value)}
            select
            label="Card Type"
            fullWidth
            size="small"
            variant="outlined"
          >
            <MenuItem value={t('resource.all.plural')}>
              {t('resource.all.plural')}
            </MenuItem>
            <MenuItem value={t('resource.subcontractor.plural')}>
              {t('resource.subcontractor.plural')}
            </MenuItem>
            <MenuItem value={t('resource.terminal.plural')}>
              {t('resource.terminal.plural')}
            </MenuItem>
            <MenuItem value={t('resource.customer.plural')}>
              {t('resource.customer.plural')}
            </MenuItem>
            <MenuItem value={t('resource.checkpoint.plural')}>
              {t('resource.checkpoint.plural')}
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <Formik
            enableReinitialize
            initialValues={{
              searchQuery: '',
            }}
            onSubmit={(values) => {
              if (values.searchQuery.length > 2) {
                setSearchText(values.searchQuery);
                setShowGrid(true);
                setWarning(false);
              } else if (values.searchQuery.length != 0) {
                setWarning(true);
              } else {
                setSearchText('');
                setShowGrid(true);
                setWarning(false);
              }
            }}
          >
            {() => (
              <Form className={classes.inputForCard}>
                <Field name="searchQuery">
                  {({ field }: FieldProps) => (
                    <TextField
                      className={classes.input}
                      {...field}
                      variant="outlined"
                      size="small"
                      label={'Please enter minimum 3 character'}
                      type="text"
                      error={warning}
                    />
                  )}
                </Field>
                <Button type="submit" variant="contained" color="primary">
                  {t('attributes.view')}
                </Button>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
      {showGrid && (
        <Box sx={{ marginTop: '15px' }}>
          {currentCard === 'All' && <AllGroups searchText={searchText} />}
          {currentCard === 'All' && <Redirect to={'/traffic/cards/all'} />}

          {currentCard === 'Subcontractors' && (
            <Subcontractors searchText={searchText} />
          )}
          {currentCard === 'Subcontractors' && (
            <Redirect to={'/traffic/cards/subcontractors'} />
          )}
          {currentCard === 'Terminals' && <Terminals searchText={searchText} />}
          {currentCard === 'Terminals' && (
            <Redirect exact to={'/traffic/cards/terminals'} />
          )}

          {currentCard === 'Customers' && <Customers searchText={searchText} />}
          {currentCard === 'Customers' && (
            <Redirect to={'/traffic/cards/customers'} />
          )}

          {currentCard === 'Checkpoints' && (
            <Checkpoints searchText={searchText} />
          )}
          {currentCard === 'Checkpoints' && (
            <Redirect to={'/traffic/cards/checkpoints'} />
          )}
        </Box>
      )}
    </>
  );
}
